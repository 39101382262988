import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import { classes } from './ListItem.st.css';

interface IListItemProps extends TPAComponentProps {
  children?: React.ReactNode;
  centered?: boolean;
  disablePadding?: boolean;
  disableGutters?: boolean;
  actionOnHover?: boolean;
}

export const ListItem = React.forwardRef(
  (props: OverridableComponentProps<IListItemProps, 'li'>, ref) => {
    const {
      as = 'li',
      className,
      centered,
      disablePadding,
      actionOnHover,
      disableGutters,
      ...rest
    } = props;

    const { isMobile } = useEnvironment();

    const Element = as;

    return (
      <Element
        ref={ref}
        className={cls(classes.root, className, {
          [classes.mobile]: isMobile,
          [classes.centered]: centered,
          [classes.disablePadding]: disablePadding,
          [classes.disableGutters]: disableGutters,
          [classes.hideAction]: actionOnHover,
        })}
        {...rest}
      >
        {props.children}
      </Element>
    );
  },
) as OverridableComponent<IListItemProps, 'li'>;

ListItem.displayName = 'ListItem';
ListItem.defaultProps = {
  centered: true,
};
