import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { GroupImage } from 'common/components/GroupImage';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import { Box } from 'wui/Box';

import widgetSettingsParams from '../../../../settingsParams';

import { getDirection } from './helpers';

import { classes as groupsStyles } from 'Groups/styles.st.css';
import { classes } from './GroupStripeItem.st.css';

interface IGroupStripeItemSkeletonProps {
  even?: boolean;
}

export function GroupStripeItemSkeleton(props: IGroupStripeItemSkeletonProps) {
  const settings = useSettings();
  const imageLayout = settings.get(widgetSettingsParams.imageLayout);
  return (
    <Card
      gap="SP1"
      padding="SP0"
      height="240px"
      direction={getDirection(imageLayout, props.even)}
    >
      <div className={classes.image}>
        <GroupImage />
      </div>
      <CardContent verticalAlign="middle">
        <Skeleton className={groupsStyles.groupTitle} variant="text" />
        <Skeleton
          className={groupsStyles.groupInfo}
          variant="text"
          width="50%"
        />
      </CardContent>
    </Card>
  );
}

GroupStripeItemSkeleton.displayName = 'GroupGridItemSkeleton';
