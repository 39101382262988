import React from 'react';

import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { useStyles } from '@wix/tpa-settings/react';

import { List } from 'wui/List';
import { Fade } from 'wui/Fade';
import { ErrorState } from 'wui/ErrorState';

import { LayoutProps } from '../types';
import sideBySideStyles from '../../../../SideBySide/stylesParams';
import { EmptyGroupList } from '../../../EmptyGroupList';

import { GroupStripeItem, GroupStripeItemSkeleton } from './GroupStripeItem';

function getPlaceholders(count: number) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupStripeItemSkeleton key={index} even={index % 2 === 0} />
    </Fade>
  ));
}

export const SideBySideLayout: React.FC<LayoutProps> = (props) => {
  const { groups, status } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const styles = useStyles();

  if (status.fetch?.loading) {
    return (
      <List gap={styles.get(sideBySideStyles.sideBySideCardSpacing)}>
        {getPlaceholders(props.batchLength)}
      </List>
    );
  }

  if (status.fetch?.error) {
    return (
      <ErrorState
        onRetry={props.onRetry}
        title={t('groups-web.error-state.title')}
        subtitle={t('groups-web.toast.error.groups.query')}
        actionLabel={t('groups-web.error-state.retry.label')}
      />
    );
  }

  if (!groups.length) {
    return <EmptyGroupList />;
  }

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          title={t('groups-web.error-state.title')}
          subtitle={t('groups-web.error-state.subtitle')}
          actionLabel={t('groups-web.error-state.retry.label')}
        />
      }
    >
      <List
        gap={styles.get(sideBySideStyles.sideBySideCardSpacing)}
        padding="SP0"
      >
        {groups.map((group, index) => (
          <GroupStripeItem
            group={group}
            key={group.id}
            even={index % 2 === 0}
          />
        ))}
      </List>
    </ErrorMonitorBoundary>
  );
};

SideBySideLayout.displayName = 'SideBySideLayout';
