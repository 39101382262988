import React from 'react';
import { ButtonSize } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Replace as ReplaceIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Button } from '../Button';
import { EmptyState } from '../EmptyState';
import { Show } from '../Show';

interface IErrorStateProps extends React.ComponentProps<typeof EmptyState> {
  title: string;
  buttonProps?: React.ComponentProps<typeof Button>;
  actionLabel?: string;
  onRetry?(): void;
}

export function ErrorState(props: IErrorStateProps) {
  const { onRetry, actionLabel, buttonProps, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <EmptyState
      action={
        <Show if={!!onRetry}>
          <Button
            onClick={onRetry}
            prefixIcon={<ReplaceIcon />}
            size={isMobile ? ButtonSize.small : undefined}
            {...buttonProps}
          >
            {actionLabel}
          </Button>
        </Show>
      }
      {...rest}
    />
  );
}

ErrorState.displayName = 'ErrorState';
