import React from 'react';
import { ImageResizeOptions } from 'wix-ui-tpa';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { groupsSettingsParams } from 'settings/groups/settings';

import { IGroup } from 'store/groups';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Show } from 'wui/Show';
import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';
import { AspectRatio } from 'wui/AspectRatio';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { UISref } from 'common/router';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupGridItemAction } from './GroupGridItemAction';

import { classes as theme } from 'Groups/styles.st.css';
import { classes } from './GroupGridItem.st.css';

interface IGroupGridItemProps {
  group: IGroup;
}

export function GroupGridItem({ group }: IGroupGridItemProps) {
  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  const settings = useSettings();

  const aspectRatio = settings.get(groupsSettingsParams.imageRatio);

  return (
    <Card padding="SP0" gap="SP0" className={classes.root}>
      <Show if={settings.get(groupsSettingsParams.showImage)}>
        <AspectRatio aspectRatio={aspectRatio}>
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            <a tabIndex={-1}>
              <GroupImage
                image={group.coverImage?.image}
                resize={ImageResizeOptions.cover}
                fluid={true}
              />
            </a>
          </UISref>
        </AspectRatio>
      </Show>
      <CardContent className={classes.content}>
        <Box verticalAlign="middle">
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            <Typography className={theme.groupTitle} as="a">
              {group.name}
            </Typography>
          </UISref>
          <Show if={settings.get(groupsSettingsParams.showAdminBadge)}>
            <RoleIcon role={group.role} />
          </Show>
        </Box>
        <GroupInfo groupId={group.id as string} />
      </CardContent>
      <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
        <CardActions className={classes.actions} paddingTop="SP1">
          <GroupGridItemAction group={group} fullWidth={true} />
        </CardActions>
      </Show>
    </Card>
  );
}

GroupGridItem.displayName = 'GroupGridItem';
