import React from 'react';

import { Box } from '../Box';

interface ICardActionsProps extends React.ComponentProps<typeof Box> {
  children?: React.ReactNode;
}

export function CardActions({ children, ...rest }: ICardActionsProps) {
  return (
    <Box padding="SP0 SP5" {...rest}>
      {children}
    </Box>
  );
}
