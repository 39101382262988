import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Skeleton } from 'wui/Skeleton';
import { TextField } from 'wui/TextField';

import { GROUP_QUESTION_ANSWER_ROOT } from './dataHooks';

interface IGroupQuestionProps extends React.ComponentProps<typeof TextField> {
  index: number;
  question: string;
}

export function GroupQuestion(props: IGroupQuestionProps) {
  const { index, question } = props;

  const { t } = useTranslation();

  const label = `${index}. ${question} ${props.required ? '*' : ''}` as const;

  return (
    <TextField
      bw
      label={label}
      data-hook={GROUP_QUESTION_ANSWER_ROOT}
      placeholder={t('groups-web.membership-questions.response.placeholder')}
      {...props}
    />
  );
}

GroupQuestion.displayName = 'GroupQuestion';

export function GroupQuestionSkeleton() {
  return (
    <Box direction="vertical" gap="SP0">
      <Skeleton variant="text" width="30%" height="16px" />
      <Skeleton variant="rect" width="100%" height="30px" />
    </Box>
  );
}
