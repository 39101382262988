import React from 'react';
import { useSelector } from 'react-redux';

import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { leaveGroupClick } from '@wix/bi-logger-groups/v2';

import { selectDialog, selectGroup } from 'store/selectors';

import {
  DIALOG_CANCEL_BUTTON,
  DIALOG_OK_BUTTON,
} from 'common/components/Dialog';
import { useController } from 'common/context/controller';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { GroupMembershipButton } from '../GroupMembershipButton';

import { LEAVE_GROUP_DIALOG } from './dataHooks';

export function LeaveGroupDialog() {
  const { t } = useTranslation();
  const bi = useBi();
  const { application$, group$ } = useController();
  const { isMobile } = useEnvironment();

  const dialog = useSelector(selectDialog('leaveGroup'));
  const groupId = dialog.params?.groupId as string;
  const group = useSelector(selectGroup(groupId));

  if (!group) {
    return null;
  }

  return (
    <AlertDialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      paperProps={{ 'data-hook': LEAVE_GROUP_DIALOG }}
    >
      <DialogTitle alert title={t('groups-web.group.actions.leave.group')} />
      <DialogContent>
        <DialogContentText>
          {t('groups-web.group.actions.leave.confirm', {
            groupName: group.name,
            interpolation: { escapeValue: false },
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          bw
          secondary
          onClick={handleClose}
          data-hook={DIALOG_CANCEL_BUTTON}
          fullWidth={isMobile}
        >
          {t('groups-web.cancel')}
        </Button>
        <GroupMembershipButton
          bw
          groupId={groupId}
          onClick={handleSubmit}
          data-hook={DIALOG_OK_BUTTON}
          fullWidth={isMobile}
        >
          {t('groups-web.group.actions.leave')}
        </GroupMembershipButton>
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    group$.leave(groupId);
    bi.report(
      leaveGroupClick({
        group_id: groupId,
        origin: 'leave_dialog_modal',
      }),
    );
  }

  function handleClose() {
    application$.closeDialog('leaveGroup');
  }
}

LeaveGroupDialog.displayName = 'LeaveGroupDialog';
